
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Container from "@/models/Container.model";
import {TABLE_FOOTER_OPTIONS} from '@/misc/TableFooterOptions';
import Employee from "@/models/Employee";

@Component
export default class ConfirmDeleteEmployeeComponent extends Vue {
  @Prop({required: true})
  private deletedEmployee!: Employee;

  @Prop({required: true})
  private onDeleteConfirmed!: () => Promise<void>;

  /**
   * indicates if the button is loading
   */
  private isLoading = false;

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDeleteDialog', deleted);
  }

  /**
   * is called when the delete button is clicked, closes modal and tells the info feed view to delete the item
   * @private
   */
  private async onDeletePressed(): Promise<void> {
    await this.onDeleteConfirmed();
  }
}
